import { useLeadDeleteMutation } from 'app/dashboard/store/api/leadApi';

import type { IErrorResponseSimplified } from '@projectTypes/apiTypes';

export const useLeadDelete = () => {
   const [leadDelete, { isError, error, isLoading, isSuccess }] = useLeadDeleteMutation();

   const leadDeleteData = async (id: string) => {
      try {
         const result = await leadDelete({
            id,
         }).unwrap();
         console.log(result);
         return result;
      } catch (err) {
         const customError = err as IErrorResponseSimplified;
         console.log(customError);

         if ('data' in customError) {
            console.error('Ошибка API:', customError.data.message);
            throw new Error(customError.data.message);
         } else {
            console.error('Системная ошибка:', err);
            throw new Error('Неизвестная ошибка');
         }
      }
   };

   return {
      leadDeleteData,
      isError,
      error: error as IErrorResponseSimplified,
      isLoading,
      isSuccess,
   };
};
