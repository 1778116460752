import React, { useEffect, useState } from 'react';

import { useToggleModal } from '@hooks/hooksUI';
import { useRuntimeLogsData } from '@hooks/hooksApi/logs';

import { LogsHeader } from '../logsHeader';
import { ColumnDashboard } from '@shared/column';
import { LogsNoneData } from '../logsNoneData';
import { LogsData } from '../logsData';
import { LoaderDefault } from '@shared/loader';
import { ErrorMessage } from '@shared/errorMessage';
import { LogsFilters } from '../logsFilters';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';

export const Logs = () => {
   const [page, setPage] = useState(1);

   const { runtimeLogIsLoading, runtimeLogData, runtimeLogIsError, runtimeLogIsSuccess } =
      useRuntimeLogsData({ page });

   const { isOpen: isOpenModalFilters, toggle: toggleModalFilters } = useToggleModal();

   if (runtimeLogIsError) return <ErrorMessage message="ошибка" />;

   return (
      <ColumnDashboard gap="32px">
         <ProfileHeader title="Логи"></ProfileHeader>
         <LogsFilters onClickOpenModal={toggleModalFilters} />

         {runtimeLogData?.data.length === 0 ? (
            <LogsNoneData />
         ) : runtimeLogIsLoading ? (
            <LoaderDefault stylesInner={{ justifyContent: 'center' }} />
         ) : (
            <LogsData page={page} setPage={setPage} data={runtimeLogData!} />
         )}
      </ColumnDashboard>
   );
};
