import React, { ChangeEvent, useState, FC } from 'react';

import { useToggleModal as useTogglePopUp } from '@hooks/hooksUI';

import { Box, SelectChangeEvent } from '@mui/material';
import { InputDefault } from '@shared/inputs';
import { SelectDefault } from '@shared/selects';
import { ButtonDefault } from '@shared/buttons';
import { AlertDefault, AlertClosable } from '@shared/alert';
import { TooltipArrow } from '@shared/tooltips';
import { ErrorMessage } from '@shared/errorMessage';

import { mapOptions } from '@utils/mappers';
import { handleCreateProjectsSubmit } from 'app/dashboard/modules/projects/utils';

import type { IProjectCreate } from '@projectTypes/projects/projectTypes';

import {
   labelStyles,
   formCreateProjectsStyles,
   inputStyles,
   formBoxButtonStyles,
} from './style';

import {
   businessAreaLabels,
   scenarioTypeLabels,
   contactBaseTypeLabels,
} from '@constants/options';

import type { FormCreateProjectsProps } from './type';

import localesRU from 'locales/ru/filterCleints.json';

const businessAreaOptions = mapOptions(businessAreaLabels);
const scenarioTypeOptions = mapOptions(scenarioTypeLabels);
const contactBaseTypeOptions = mapOptions(contactBaseTypeLabels);

export const FormProject: FC<FormCreateProjectsProps> = ({
   onSubmit,
   isError,
   error,
   isLoading,
   isOpenNotificationsAlert = false,

   isSuccess = false,
   isNotifications = false,
   isNotificationsMessage = '',
   successMessage,
   name = '',
   businessArea = '',
   contactBaseType = '',
   scenarioType = '',
   buttonSubmitText = 'Далее',
}) => {
   const [formData, setFormData] = useState<IProjectCreate>({
      name: name,
      business_area: businessArea,
      scenario_type: scenarioType,
      contact_base_type: contactBaseType,
      is_draft: true,
   });

   const { isOpen: isOpenTooltipArea, toggle: toggleTooltipArea } = useTogglePopUp();
   const { isOpen: isOpenTooltipInvolvement, toggle: toggleTooltipInvolvement } =
      useTogglePopUp();

   const [errors, setErrors] = useState({
      name: '',
      business_area: '',
      scenario_type: '',
      contact_base_type: '',
   });

   const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;

      setFormData((prevData) => ({ ...prevData, [name]: value }));
   };

   const handleSelectChange =
      (name: keyof typeof formData) => (event: SelectChangeEvent<string>) => {
         setFormData((prevData) => ({ ...prevData, [name]: event.target.value }));
         setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
      };

   const handleSubmit = async (e: React.FormEvent) => {
      const errorsMessage = localesRU.createProjectFieldsErrors;

      await handleCreateProjectsSubmit({
         e,
         errorsMessage,
         formData,
         onSubmit,
         setErrors,
      });
   };

   return (
      <form style={formCreateProjectsStyles} onSubmit={handleSubmit}>
         {(isError || isSuccess) && (
            <AlertDefault
               severity={isError ? 'error' : 'success'}
               message={isError ? error.data.data.message : successMessage!}
            />
         )}
         {isNotifications && (
            <>
               {isOpenNotificationsAlert && (
                  <AlertDefault severity={'info'} message={isNotificationsMessage} />
               )}
               <AlertClosable
                  open={isNotifications}
                  message={isNotificationsMessage}
                  severity="info"
               />
            </>
         )}

         <InputDefault
            stylesWrapper={inputStyles}
            onChange={handleChange}
            value={formData.name}
            placeholder="Название проекта"
            labelStyles={labelStyles}
            labelVariant="overline"
            label="Необходимо для внутреннего использования"
            name="name"
            error={
               errors.name ? (
                  <ErrorMessage
                     sxInner={{
                        fontSize: '12px',
                     }}
                     message="Минимальная длина 10 символов"
                  />
               ) : undefined
            }
         />
         <Box>
            <SelectDefault
               isDefaultValue={!!formData.business_area}
               placeholder="Сфера проекта"
               onChange={handleSelectChange('business_area')}
               arrValues={businessAreaOptions}
               value={formData.business_area}
               label="Укажите вашу сферу"
               labelStyles={labelStyles}
               labelVariant="overline"
               sx={{
                  width: '100%',
               }}
               error={Boolean(errors.business_area)}
            />

            {errors.business_area && <ErrorMessage message={errors.business_area} />}
         </Box>

         <TooltipArrow
            isOpen={isOpenTooltipArea}
            title={
               <p
                  dangerouslySetInnerHTML={{
                     __html: localesRU.createProjectTooltipMessage.business_area,
                  }}
               />
            }
            placement="top"
         >
            <Box>
               <SelectDefault
                  isDefaultValue={!!formData.scenario_type}
                  onOpen={toggleTooltipArea}
                  onClose={toggleTooltipArea}
                  placeholder="Направление сценария"
                  onChange={handleSelectChange('scenario_type')}
                  arrValues={scenarioTypeOptions}
                  value={formData.scenario_type}
                  label="Укажите ваше направление"
                  labelStyles={labelStyles}
                  labelVariant="overline"
                  sx={{
                     width: '100%',
                  }}
                  error={Boolean(errors.scenario_type)}
               />
               {errors.scenario_type && <ErrorMessage message={errors.scenario_type} />}
            </Box>
         </TooltipArrow>
         <TooltipArrow
            isOpen={isOpenTooltipInvolvement}
            title={
               <p
                  dangerouslySetInnerHTML={{
                     __html: localesRU.createProjectTooltipMessage.scenario_type,
                  }}
               />
            }
            placement="top"
         >
            <Box>
               <SelectDefault
                  isDefaultValue={!!formData.contact_base_type}
                  onOpen={toggleTooltipInvolvement}
                  onClose={toggleTooltipInvolvement}
                  placeholder="Вовлеченность контактов"
                  onChange={handleSelectChange('contact_base_type')}
                  arrValues={contactBaseTypeOptions}
                  value={formData.contact_base_type}
                  label="Советуем разделять проекты по типу вовлеченности контактов"
                  labelStyles={labelStyles}
                  labelVariant="overline"
                  sx={{
                     width: '100%',
                  }}
                  error={Boolean(errors.contact_base_type)}
               />
               {errors.contact_base_type && (
                  <ErrorMessage message={errors.contact_base_type} />
               )}
            </Box>
         </TooltipArrow>

         <Box sx={formBoxButtonStyles}>
            <ButtonDefault
               type="submit"
               label={buttonSubmitText}
               onClick={() =>
                  setFormData((prevData) => ({
                     ...prevData,
                     is_draft: false,
                  }))
               }
               disabled={isLoading}
            />
         </Box>
      </form>
   );
};
