import React, { useEffect, useState } from 'react';

import { useTheme } from '@hooks/useTheme';
import { useLeadData } from '@hooks/hooksApi';

import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { ColumnDashboard } from '@shared/column';
import { ButtonDefault } from '@shared/buttons';
import { ClientsNoneData } from '../clientsNoneData';
import { ModalClientAdd } from '../modalClientAdd';
import { ClientsData } from '../clientsData';
import { LoaderDefault } from '@shared/loader';
import { ErrorMessage } from '@shared/errorMessage';
import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';
import { ClientsFilters, ModalClientsFilters } from '../clientsData/components';

import type { ILeadResponse } from '@projectTypes/lead';

import { clientsHeaderBoxStyles, clientsHeaderButtonStyles } from './styles';
import { useToggleModal } from '@hooks/hooksUI';

export const Clients = () => {
   const [openModalClientAdd, setOpenModalClientAdd] = useState(false);

   const [page, setPage] = useState(1);

   const { isOpen: isOpenModalFilters, toggle: toggleModalFilters } = useToggleModal();

   const { dataLead, isErrorLead, isLoadingLead, error, refetchLead, isFetchingLead } =
      useLeadData({ page });

   const handleCloseModalClientAdd = () => {
      setOpenModalClientAdd(false);
   };

   const handleOpenModalClientAdd = () => {
      setOpenModalClientAdd(true);
   };

   const { theme } = useTheme();

   return (
      <ColumnDashboard>
         <ModalClientsFilters open={isOpenModalFilters} onClose={toggleModalFilters} />
         <ModalClientAdd
            refetch={refetchLead}
            open={openModalClientAdd}
            onClose={handleCloseModalClientAdd}
         />
         <ProfileHeader
            styles={{ ...(clientsHeaderBoxStyles(theme) as object) }}
            title="Мои клиенты"
         >
            <ButtonDefault
               onClick={handleOpenModalClientAdd}
               startIcon={<IconPlus />}
               label=""
               sx={clientsHeaderButtonStyles() as object}
            />
         </ProfileHeader>
         {isErrorLead ? (
            <ErrorMessage message={error.data.data.message} />
         ) : isLoadingLead || isFetchingLead ? (
            <LoaderDefault stylesInner={{ justifyContent: 'center' }} />
         ) : dataLead?.data?.length === 0 && page === 1 ? (
            <ClientsNoneData />
         ) : (
            <>
               <ClientsFilters onClickOpenModal={toggleModalFilters} />
               <ClientsData
                  page={page}
                  setPage={setPage}
                  refetch={refetchLead}
                  dataLead={dataLead as ILeadResponse}
               />
            </>
         )}
      </ColumnDashboard>
   );
};
