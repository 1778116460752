import React, { FC } from 'react';

import type { HistoryScriptHeaderProps } from './type';

export const HistoryScriptHeader: FC<HistoryScriptHeaderProps> = ({
   uuid,
   status,
   dataFinish,
   dataStart,
}) => {
   return (
      <div>
         {uuid} <br />
         {status} <br />
         {dataFinish} <br />
         {dataStart} <br />
      </div>
   );
};
