import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { IManagerResponse } from '@projectTypes/manager';

import {
   apiDashboard,
   apiDashboardManager,
   apiDashboardManagerAttachProjects,
} from '@constants/api/endpoints';

const baseUrl = process.env.REACT_APP_API;

export const managerApi = createApi({
   reducerPath: 'manager',
   baseQuery: fetchBaseQuery({
      baseUrl: `${baseUrl}${apiDashboard}`,
      prepareHeaders: (headers) => {
         headers.set('Content-Type', 'application/json');
         headers.set('Accept', 'application/json');
         const token = document.cookie
            .split('; ')
            .find((row) => row.startsWith('authToken='))
            ?.split('=')[1];
         if (token) {
            headers.set('Authorization', `Bearer ${token}`);
         }
         return headers;
      },
   }),

   endpoints: (builder) => ({
      manager: builder.query<IManagerResponse, void>({
         query: () => ({
            url: apiDashboardManager as string,
         }),
      }),
      managerId: builder.query<void, { id: string }>({
         query: ({ id }) => ({
            url: `${apiDashboardManager}/${id}`,
         }),
      }),
      managerIdProjects: builder.query<void, { id: string }>({
         query: ({ id }) => ({
            url: `${apiDashboardManager}/${id}/projects`,
         }),
      }),
      managerAttachProject: builder.mutation<void, { id: string | number }>({
         query: ({ id }) => ({
            url: `${apiDashboardManager}/${id}${apiDashboardManagerAttachProjects}`,
            body: {
               project_ids: ['1'], // чуть позже чеез пармтеры буду предать нужные ids
            },
            method: 'POST',
         }),
      }),
   }),
});

export const {
   useManagerQuery,
   useManagerIdQuery,
   useManagerAttachProjectMutation,
   useManagerIdProjectsQuery,
} = managerApi;
