import { useManagerQuery } from 'app/dashboard/store/api/managerApi';

import type { IErrorResponse } from '@projectTypes/apiTypes';

export const useManager = () => {
   const {
      data: dataManager,
      isLoading: isLoadingManager,
      isError: isErrorManager,
      error: errorManager,
   } = useManagerQuery();

   return {
      dataManager,
      isLoadingManager,
      isErrorManager,
      errorManager: errorManager as IErrorResponse,
   };
};
