import React, { FC } from 'react';

import { Box } from '@mui/material';
import { HistoryScriptHeader } from '../historyScriptHeader';
import { HistoryScriptInner } from '../historyScriptInner';

import type { HistoryScriptProps } from './type';

export const HistoryScript: FC<HistoryScriptProps> = ({ data, isSuccess }) => {
   const { history, runtime, runtime_finished_at, runtime_started_at, status } =
      data.data;

   const historyArr = isSuccess ? Object.entries(history || {}) : [];

   return (
      <Box>
         <HistoryScriptHeader
            status={status}
            uuid={runtime}
            dataStart={runtime_started_at}
            dataFinish={runtime_finished_at}
         />
         <HistoryScriptInner history={historyArr} />
      </Box>
   );
};
