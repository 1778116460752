import React from 'react';

import { useRuntimeLogsByUiidData } from '@hooks/hooksApi/logs';

import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '@shared/errorMessage';
import { ColumnDashboard } from '@shared/column';
import { ProfileHeader } from '@componentsDashBoard/profileHeader';
import { HistoryScript } from '@componentsDashBoard/historyScript';

import type { ILogsHistoryStep } from '@projectTypes/logs';
import { LoaderDefault } from '@shared/loader';
export const LogsHistory = () => {
   const { slug } = useParams();

   const {
      runtimeLogHistoryData,
      runtimeLogHistorytIsLoading,
      runtimeLogHistoryIsError,
      runtimeLogHistoryIsSuccess,
   } = useRuntimeLogsByUiidData({ uuid: slug! });

   if (runtimeLogHistoryIsError) return <ErrorMessage message="error!" />;

   return (
      <ColumnDashboard gap={'32px'}>
         <ProfileHeader title={`Логи ${slug}`} />
         <Typography>{runtimeLogHistoryData?.data.status}</Typography>

         {runtimeLogHistorytIsLoading ? (
            <LoaderDefault stylesInner={{ justifyContent: 'center' }} />
         ) : (
            <HistoryScript
               data={runtimeLogHistoryData!}
               isSuccess={runtimeLogHistoryIsSuccess}
            />
         )}
      </ColumnDashboard>
   );
};
